import { WppButton, WppIconGear, WppSpinner } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useProcessApi } from 'api/process/queries/useFetchProcessApi'
import { Permission } from 'constants/permission'
import { Tenants } from 'constants/tenants'
import { useHasPermission } from 'hooks/useHasPermissions'
import { showEditProcessModal, useEditProcessModal } from 'pages/processBuilder/editProcessModal/EditProcessModal'
import { EmptyState } from 'pages/processBuilder/emptyState/EmptyState'
import styles from 'pages/processBuilder/ProcessBuilder.module.scss'
import { templates } from 'pages/processBuilder/templates'
import { getPreviewImageUrl, getInitialValues } from 'pages/processBuilder/utils'

export const ProcessBuilder = () => {
  const { processId } = useParams()
  const {
    osContext: { tenant },
  } = useOs()
  const { data, refetch, isLoading } = useProcessApi({
    params: {
      tenantId: tenant.id,
      processId,
    },
  })

  const [config, setConfig] = useState(getInitialValues(data))
  const { hasPermission } = useHasPermission()
  const isAdmin = hasPermission(Permission.OS_PROCESS_PAGE_ENTITIES_MANAGE)
  const { visible: isEditMode } = useEditProcessModal()
  const { t } = useTranslation()
  //TODO: hardfix for [WPPLONOP-23122] remove once logo size input will be implemented
  const isMichelin = tenant.name === Tenants.Michelin

  useEffect(() => {
    setConfig(getInitialValues(data))
  }, [data])

  const resetEditState = useCallback(async () => {
    const { data: updatedData } = await refetch()
    updatedData?.data && setConfig(getInitialValues(updatedData.data) as any)
  }, [refetch])

  const handleOpenEdit = useCallback(() => {
    showEditProcessModal({ onChange: setConfig, resetEditState: resetEditState, processId: processId })
  }, [resetEditState, processId])

  const Template = templates[config.template]

  if (isLoading) return <WppSpinner className={styles.loader} size="l" />
  if (!data && !isEditMode) return <EmptyState isAdmin={isAdmin} onConfigureClick={handleOpenEdit} />

  return (
    <div
      className={clsx(styles.root, { [styles.editMode]: isEditMode })}
      style={{
        backgroundImage: `url(${getPreviewImageUrl(config?.background)})`,
        backgroundPosition: config.positionBackground,
      }}
    >
      <div className={styles.bgColor} style={{ background: config?.colors?.[0] }} />

      <Template config={config} isEditMode={isEditMode} isMichelin={isMichelin} />

      {isAdmin && !isEditMode && (
        <WppButton onClick={handleOpenEdit} className={styles.editBtn} variant="primary">
          <WppIconGear slot="icon-start" />
          {t('common.edit')}
        </WppButton>
      )}
    </div>
  )
}
