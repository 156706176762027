export enum BGAlignmentOptions {
  LeftTop = 'left top',
  CenterTop = 'center top',
  RightTop = 'right top',
  LeftCenter = 'left center',
  CenterCenter = 'center center',
  RightCenter = 'right center',
  LeftBottom = 'left bottom',
  CenterBottom = 'center bottom',
  RightBottom = 'right bottom',
}
