import { TemplateConfig } from 'types/process/template'

export const templateConfig: TemplateConfig = {
  fields: {
    template: true,
    title: true,
    description: true,
    background: true,
    positionBackground: true,
    logo: true,
    widgetBackgroundImage: false,
    widgetPartBackgroundImage: false,
    colors: true,
    button: {
      title: true,
      url: true,
      colors: true,
      isVisible: true,
    },
    phases: {
      title: true,
      description: true,
      overlayDescriptions: true,
      colors: true,
      image: false,
    },
    groups: false,
    templateOptions: {
      flipGradient: false,
    },
  },
  colors: {
    page: [{ title: 'Page background', defaultValue: 'linear-gradient(180deg, #f794a4 0%, #fdd6bd 100%)' }],
    button: [
      { title: 'Background', defaultValue: '#0014cc' },
      { title: 'Text', defaultValue: '#fff' },
    ],
    phase: [
      { title: 'Background', defaultValue: 'linear-gradient(90deg, #0014CC 0%, #03086B 100%)' },
      { title: 'Active background', defaultValue: '#CB3E22' },
      { title: 'Index text', defaultValue: '#fff' },
      { title: 'Active index text', defaultValue: '#fff' },
      { title: 'Title', defaultValue: '#fff' },
      { title: 'Overlay text', defaultValue: '#fff' },
      { title: 'Title hover', defaultValue: '#fff' },
      { title: 'Description', defaultValue: '#fff' },
      { title: 'Button background', defaultValue: '#fff' },
      { title: 'Button icon', defaultValue: '#fff' },
    ],
    group: [],
  },
}
