import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { MouseEventHandler, useCallback, useMemo } from 'react'

import styles from 'pages/processBuilder/templates/circular1/Circular1Process.module.scss'
import { Phase } from 'pages/processBuilder/templates/circular1/phase/Phase'
import { getPreviewImageUrl } from 'pages/processBuilder/utils'
import { ProcessFormConfig } from 'types/process/process'

export const Circular1Svg = ({
  activePhase,
  className,
  blurClassname,
  onPhaseEnter,
  onPhaseLeave,
  config,
  isEditMode,
}: {
  activePhase: MayBeNull<number>
  className?: string
  blurClassname?: string
  onPhaseEnter: (phase: number) => MouseEventHandler
  onPhaseLeave: MouseEventHandler
  config: ProcessFormConfig
  isEditMode: boolean
}) => {
  const isActivePhase = useCallback((phase: number) => phase === activePhase, [activePhase])
  const parrentTitle = useMemo(
    () => (typeof activePhase === 'number' ? config.phases?.[activePhase]?.groupTitle : ''),
    [activePhase, config.phases],
  )
  const phaseSize = useMemo(() => (config?.phases.length <= 3 ? 120 : 90), [config?.phases.length])

  const logo = useMemo(() => getPreviewImageUrl(config?.logo), [config?.logo])
  const ringColors = useMemo(() => {
    const colors = config.colors.slice(2, 7)

    return config.templateOptions?.flipGradient ? colors.reverse() : colors
  }, [config.colors, config.templateOptions?.flipGradient])

  return (
    <svg
      width="1280"
      height="910"
      viewBox="0 0 1280 910"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <foreignObject className={blurClassname} />
      <g transform="matrix(1, 0, 0, 1, -1.5, 67)">
        <circle
          cx="641"
          cy="385.027"
          r="300"
          stroke={config.colors[7]}
          strokeWidth="1"
          fill="transparent"
          className={styles.fade1}
        />
        <circle
          cx="641"
          cy="385.027"
          r="280"
          stroke={ringColors[0]}
          strokeWidth="40"
          fill="transparent"
          className={styles.fade1}
        />
        <circle
          cx="641"
          cy="385.027"
          r="240"
          stroke={ringColors[1]}
          strokeWidth="40"
          fill="transparent"
          className={styles.fade2}
        />
        <circle
          cx="641"
          cy="385.027"
          r="200"
          stroke={ringColors[2]}
          strokeWidth="40"
          fill="transparent"
          className={styles.fade3}
        />
        <circle
          cx="641"
          cy="385.027"
          r="160"
          stroke={ringColors[3]}
          strokeWidth="40"
          fill="transparent"
          className={styles.fade4}
        />
        <circle
          cx="641"
          cy="385.027"
          r="123"
          stroke={ringColors[4]}
          strokeWidth="35"
          fill="transparent"
          className={styles.fade5}
        />

        {config?.phases?.map((item, index) => (
          <circle
            cx="642"
            cy="93"
            r={15}
            fill={item?.colors?.[1] || 'white'}
            transform={`rotate(${config?.phases ? (360 / config?.phases.length) * index : 0} 641 385) translate(0, -8)`}
            key={index}
          />
        ))}

        {config?.phases?.map((item, index) => (
          <Phase
            key={index}
            onMouseEnter={onPhaseEnter(index)}
            onMouseLeave={onPhaseLeave}
            isActive={isActivePhase(index)}
            rotation={config?.phases ? (360 / config?.phases.length) * index : 0}
            size={phaseSize}
            hoverSize={360 / config?.phases.length}
            phase={item}
            isEditMode={isEditMode}
          />
        ))}

        <foreignObject
          width={212}
          height={212}
          x={535}
          y={279.027}
          className={clsx(styles.logoWrap, styles.fade6)}
          style={{
            background:
              (typeof activePhase === 'number' && config.phases?.[activePhase]?.colors?.[4]) || config?.colors?.[1],
          }}
        >
          <WppTypography
            type="xl-heading"
            className={styles.phaseGroupTitle}
            style={{
              opacity: parrentTitle ? 1 : 0,
              color: typeof activePhase === 'number' && config.phases?.[activePhase]?.colors?.[5],
            }}
          >
            {parrentTitle}
          </WppTypography>
          <img
            src={logo}
            alt=""
            className={styles.logo}
            style={{ opacity: parrentTitle ? 0 : 1, visibility: logo ? 'visible' : 'hidden' }}
          />
        </foreignObject>
      </g>
      <defs>
        <radialGradient
          id="toolsLine"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(645.224 145.289) rotate(91.1259) scale(249.088 119.047)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}
