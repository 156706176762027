import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/linear2/LinearProcess2.module.scss'
import { Phase } from 'pages/processBuilder/templates/linear2/phase/Phase'
import { getPreviewImageUrl } from 'pages/processBuilder/utils'
import { ProcessFormConfig } from 'types/process/process'

export const LinearProcess2 = ({ config, isEditMode }: { config: ProcessFormConfig; isEditMode: boolean }) => {
  const pageRef = useRef<HTMLDivElement>(null)
  const phaseRef = useRef<HTMLDivElement>(null)
  const [pageWidth, setPageWidth] = useState<number | undefined>(undefined)
  const [phaseWrapperWidth, setPageWrapperWidth] = useState<number | undefined>(undefined)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

  const handleHover = (index: number) => () => {
    setHoveredIndex(index)
  }

  const handleMouseOut = () => {
    setHoveredIndex(null)
  }

  useEffect(() => {
    if (!pageRef.current) {
      return
    }

    const pageResizeObserver = new ResizeObserver(() => {
      if (pageRef.current?.offsetWidth !== pageWidth) {
        setPageWidth(pageRef.current?.offsetWidth)
      }
      if (phaseRef.current?.clientWidth !== phaseWrapperWidth) {
        setPageWrapperWidth(phaseRef.current?.clientWidth)
      }
    })

    pageResizeObserver.observe(pageRef.current)

    return () => {
      pageResizeObserver.disconnect()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageRef.current, phaseRef.current])

  const defaultPhasesShift = 50
  const phasesCount = config.phases.length

  const phaseSize = useMemo(
    () => (pageWidth ? (pageWidth + phasesCount * defaultPhasesShift) / phasesCount : 0),
    [pageWidth, phasesCount],
  )
  const calculateSize = useCallback(
    (index: number) => {
      return index === 0 || index === phasesCount - 1 ? phaseSize * 0.75 : phaseSize
    },
    [phaseSize, phasesCount],
  )

  const firstPhaseSize = useMemo(() => {
    const firstPhaseBaseSize = calculateSize(0)

    if (phasesCount <= 2) {
      return firstPhaseBaseSize < 350 ? firstPhaseBaseSize : 350
    } else {
      return firstPhaseBaseSize < 160 ? firstPhaseBaseSize : 160
    }
  }, [phasesCount, calculateSize])

  const calculatePhaseShift = (index: number) => {
    if (index === phasesCount - 1) {
      return 0
    }

    if (phasesCount > 2 && (index === 0 || index === phasesCount - 2)) {
      return 20
    }

    return defaultPhasesShift
  }

  return (
    <div className={styles.root}>
      <Flex justify="center" align="center" className={styles.page} ref={pageRef}>
        <Flex justify="between" className={styles.headerWrapper}>
          <div>
            <WppTypography type="4xl-display" className={styles.title} style={{ color: config.colors[1] }}>
              {config.title}
            </WppTypography>
            <WppTypography type="s-body" style={{ color: config.colors[2] }}>
              {config.description}
            </WppTypography>
          </div>
          <img src={getPreviewImageUrl(config?.logo)} alt="" className={styles.logo} />
        </Flex>
        <Link
          to={config?.button?.url || ''}
          style={{ visibility: config?.button?.isVisible ? 'visible' : 'hidden' }}
          className={styles.projectButtonLink}
        >
          <WppButton
            className={styles.projectButton}
            style={{ background: config?.button?.colors?.[0], color: config?.button?.colors?.[1] }}
          >
            {config?.button?.title}
          </WppButton>
        </Link>
        <Flex align="center" ref={phaseRef} className={styles.phasesContainer}>
          {phasesCount >= 2 && (
            <div
              className={styles.arrowLine}
              style={{
                width: `calc(100% - ${firstPhaseSize}px)`,
                height: `${firstPhaseSize}px`,
              }}
            />
          )}

          {config?.phases.map((phase, index: number) => {
            const isSmall = phasesCount > 2 && (index === 0 || index === phasesCount - 1)

            return (
              <Phase
                key={index}
                onMouseEnter={handleHover(index)}
                onMouseLeave={handleMouseOut}
                hoveredIndex={hoveredIndex}
                isActive={hoveredIndex === index}
                isBlured={hoveredIndex !== index && hoveredIndex !== null}
                isEditMode={isEditMode}
                phase={phase}
                size={calculateSize(index)}
                isSmall={isSmall}
                phasesShift={calculatePhaseShift(index)}
                zIndex={phasesCount - index}
                withArrow={!index && phasesCount > 1}
              />
            )
          })}
        </Flex>
      </Flex>
    </div>
  )
}
