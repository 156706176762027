// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wg4qV{position:relative;max-width:var(--wpp-os-content-max-width);height:calc(100vh - var(--wpp-os-header-height));margin:0 auto}.EAj3Y{height:100%}.JSOLF{position:absolute;top:24px;right:28px}.yoJth{color:var(--wpp-grey-color-900);background:var(--wpp-white-color);border-radius:4px}.yoJth::part(button){color:inherit;background:inherit;border-radius:4px}.yoJth::part(text){color:inherit}.jEO2z{overflow:visible}.pWwsq{border-radius:100%;transform-origin:center;transition:transform .3s ease;pointer-events:none}._180b{transform:scale(0.5050505051)}.vcmx4{width:100%;height:100%;object-fit:contain}", "",{"version":3,"sources":["webpack://./src/pages/processBuilder/templates/circular4/Circular4Process.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,yCAAA,CACA,gDAAA,CACA,aAAA,CAGF,OACE,WAAA,CAGF,OACE,iBAAA,CACA,QAAA,CACA,UAAA,CAGF,OACE,+BAAA,CACA,iCAAA,CACA,iBAAA,CAEA,qBACE,aAAA,CACA,kBAAA,CACA,iBAAA,CAGF,mBACE,aAAA,CAIJ,OACE,gBAAA,CAGF,OACE,kBAAA,CACA,uBAAA,CACA,6BAAA,CACA,mBAAA,CAGF,OACE,6BAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,kBAAA","sourcesContent":[".root {\n  position: relative;\n  max-width: var(--wpp-os-content-max-width);\n  height: calc(100vh - var(--wpp-os-header-height));\n  margin: 0 auto;\n}\n\n.svgWrapper {\n  height: 100%;\n}\n\n.projectLink {\n  position: absolute;\n  top: 24px;\n  right: 28px;\n}\n\n.projectButton {\n  color: var(--wpp-grey-color-900);\n  background: var(--wpp-white-color);\n  border-radius: 4px;\n\n  &::part(button) {\n    color: inherit;\n    background: inherit;\n    border-radius: 4px;\n  }\n\n  &::part(text) {\n    color: inherit;\n  }\n}\n\n.svg {\n  overflow: visible;\n}\n\n.logoWrap {\n  border-radius: 100%;\n  transform-origin: center;\n  transition: transform 0.3s ease;\n  pointer-events: none;\n}\n\n.logoWrapActive {\n  transform: scale(calc(1 / 1.98));\n}\n\n.logo {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Wg4qV",
	"svgWrapper": "EAj3Y",
	"projectLink": "JSOLF",
	"projectButton": "yoJth",
	"svg": "jEO2z",
	"logoWrap": "pWwsq",
	"logoWrapActive": "_180b",
	"logo": "vcmx4"
};
export default ___CSS_LOADER_EXPORT___;
