import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse, AttachmentMetadata } from '@wpp-open/core'

import { BGAlignmentOptions, Dimensions } from 'types/common/comon'

export enum Templates {
  Circular1 = 'FIRST_CIRCULAR',
  Circular2 = 'SECOND_CIRCULAR',
  Circular3 = 'THIRD_CIRCULAR',
  Circular4 = 'FOURTH_CIRCULAR',
  Circular5 = 'FIFTH_CIRCULAR',
  Linear1 = 'FIRST_LINEAR',
  Linear2 = 'SECOND_LINEAR',
}

export enum FirstLinearPhaseImageSize {
  FULL_WIDTH = 'fullWidth',
  HALF_WIDTH = 'halfWidth',
}

export interface PhaseTemplateOptions {
  phaseImageSize?: MayBeNull<FirstLinearPhaseImageSize>
  expandedPhaseImageVisible?: MayBeNull<boolean>
  minimizedPhaseImageVisible?: MayBeNull<boolean>
}

export interface Phase {
  id: string
  processId: MayBeNull<string>
  order: MayBeNull<number>
  title: MayBeNull<string>
  innerPageEnabled: boolean
  groupTitle: MayBeNull<string>
  overlayDescriptions: string[]
  description: MayBeNull<string>
  image: MayBeNull<AttachmentMetadataResponse>
  colors: string[]
  url: MayBeNull<string>
  templateOptions?: PhaseTemplateOptions
}

export interface CreatePhase extends Omit<Partial<Phase>, 'id' | 'processId'> {}

export interface EditPhase
  extends Omit<Partial<Phase>, 'id' | 'innerPageEnabled' | 'overlayDescriptions' | 'colors' | 'processId'> {
  id: string
  innerPageEnabled?: MayBeNull<boolean>
  overlayDescriptions?: MayBeNull<string[]>
  colors?: MayBeNull<string[]>
}

export interface Group {
  id: string
  processId: string
  order: MayBeNull<number>
  title: MayBeNull<string>
  description: MayBeNull<string>
  colors: string[]
  phaseOrders: number[]
}

export interface CreateGroup extends Omit<Partial<Group>, 'id' | 'processId'> {}

export interface EditGroup extends Omit<Partial<Group>, 'id' | 'processId' | 'colors' | 'phaseOrders'> {
  id: string
  colors?: MayBeNull<string[]>
  phaseOrders?: MayBeNull<number[]>
}

export interface ProcessButton {
  id: string
  processId: MayBeNull<string>
  title: MayBeNull<string>
  url: MayBeNull<string>
  colors: string[]
  isVisible: boolean
}

export interface CreateButton extends Omit<Partial<ProcessButton>, 'id' | 'processId'> {}

export interface EditButton extends Omit<Partial<ProcessButton>, 'id' | 'processId' | 'colors' | 'isVisible'> {
  colors?: MayBeNull<string[]>
  isVisible?: MayBeNull<boolean>
}

export interface TemplateOptions {
  flipGradient?: boolean
  isInactivePhaseBlured?: boolean
  verticalPhaseNumbers?: boolean
  widgetSize?: WidgetSize
}
export enum WidgetSize {
  MD = 'MD',
  LG = 'LG',
}

export interface Process {
  id: string
  title: MayBeNull<string>
  description: MayBeNull<string>
  tenantId: string
  template: Templates
  colors: string[]
  button: MayBeNull<ProcessButton>
  phases: Phase[]
  groups: Group[]
  createdAt: string
  createdBy: string
  updatedAt: MayBeNull<string>
  updatedBy: MayBeNull<string>
  background: MayBeNull<AttachmentMetadataResponse>
  positionBackground: BGAlignmentOptions
  logoImage: MayBeNull<AttachmentMetadataResponse>
  logoDimensions: MayBeNull<Dimensions>
  widgetBackgroundImage: MayBeNull<AttachmentMetadataResponse>
  widgetPartBackgroundImage: MayBeNull<AttachmentMetadataResponse>
  headerBackground: MayBeNull<AttachmentMetadataResponse>
  templateOptions?: TemplateOptions
  isConfigured: boolean
}

export interface CreateProcessPayload {
  title?: MayBeNull<string>
  description?: MayBeNull<string>
  tenantId: string
  template: Templates
  colors?: string[]
  button?: MayBeNull<CreateButton>
  phases?: CreatePhase[]
  groups?: CreateGroup[]
  background?: MayBeNull<AttachmentMetadata>
  positionBackground?: BGAlignmentOptions
  logoImage?: MayBeNull<AttachmentMetadata>
  logoDimensions: Dimensions
  widgetBackgroundImage?: MayBeNull<AttachmentMetadata>
  widgetPartBackgroundImage?: MayBeNull<AttachmentMetadata>
  headerBackground?: MayBeNull<AttachmentMetadata>
  templateOptions?: MayBeNull<TemplateOptions>
  isConfigured?: boolean
}

export interface EditProcessPayload {
  title?: MayBeNull<string>
  description?: MayBeNull<string>
  phases?: MayBeNull<EditPhase | CreatePhase>
  groups?: MayBeNull<EditGroup | CreateGroup>
  button?: MayBeNull<EditButton>
  template?: MayBeNull<Templates>
  colors?: MayBeNull<string[]>
  background?: MayBeNull<AttachmentMetadata>
  positionBackground?: BGAlignmentOptions
  logoImage?: MayBeNull<AttachmentMetadata>
  logoDimensions?: MayBeNull<Dimensions>
  widgetBackgroundImage?: MayBeNull<AttachmentMetadata>
  widgetPartBackgroundImage?: MayBeNull<AttachmentMetadata>
  headerBackground?: MayBeNull<AttachmentMetadata>
  templateOptions?: MayBeNull<TemplateOptions>
  isConfigured?: MayBeNull<boolean>
}

export interface FormPhase extends Omit<Phase, 'overlayDescriptions' | 'image' | 'id' | 'processId' | 'order'> {
  overlayDescriptions: { label: string; id: number }[]
  image?: [FileItemType?]
  id?: string
  localId?: string
}

export interface FormPhaseWithIndex extends FormPhase {
  phaseIndex: number
}

export interface FormGroup extends Omit<Group, 'phaseOrders' | 'id' | 'processId' | 'order'> {
  phasesLocalIds: string[]
  id?: string
}

export interface ProcessFormConfig
  extends Omit<
    Process,
    | 'id'
    | 'tenantId'
    | 'createdAt'
    | 'createdBy'
    | 'background'
    | 'logoImage'
    | 'logoDimensions'
    | 'button'
    | 'phases'
    | 'groups'
    | 'widgetBackgroundImage'
    | 'widgetPartBackgroundImage'
    | 'headerBackground'
    | 'updatedAt'
    | 'updatedBy'
    | 'isConfigured'
  > {
  background: [FileItemType?]
  logoImage: [FileItemType?]
  logoDimensions: Dimensions
  widgetBackgroundImage: [FileItemType?]
  widgetPartBackgroundImage: [FileItemType?]
  headerBackground: [FileItemType?]
  button: Omit<Partial<ProcessButton>, 'id' | 'processId'>
  phases: FormPhase[]
  groups: FormGroup[]
}

export type MapPayload<T> = T extends true ? EditProcessPayload : T extends false ? CreateProcessPayload : never
