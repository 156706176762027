import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/linear1/group/Group.module.scss'
import { Phase } from 'pages/processBuilder/templates/linear1/phase/Phase'
import { FormGroup, FormPhaseWithIndex } from 'types/process/process'

interface Props {
  pageWidth: number | undefined
  group?: FormGroup
  phases: FormPhaseWithIndex[]
  numberOfAllPhases: number
  isVerticalPhaseNumber: boolean
  activePhaseIndex: number
  handlePhaseChange: (index: number) => void
}

export const Group = ({
  pageWidth,
  group,
  phases,
  numberOfAllPhases,
  isVerticalPhaseNumber,
  activePhaseIndex,
  handlePhaseChange,
}: Props) => {
  return (
    <div>
      <Flex gap={4}>
        {phases.map(phase => (
          <Phase
            key={phase.phaseIndex}
            pageWidth={pageWidth}
            numberOfAllPhases={numberOfAllPhases}
            isVerticalPhaseNumber={isVerticalPhaseNumber}
            phase={phase}
            index={phase.phaseIndex}
            isActive={phase.phaseIndex === activePhaseIndex}
            isAnyActive={activePhaseIndex !== 0}
            onPhaseClick={handlePhaseChange}
          />
        ))}
      </Flex>
      {group && (
        <>
          <Flex className={styles.footer} gap={10}>
            <div className={styles.leftBorder} style={{ borderColor: group?.colors?.[0] }} />
            <WppTypography type="s-strong" className={styles.groupTitle} style={{ color: group?.colors?.[1] }}>
              {group.title}
            </WppTypography>
            <div className={styles.rightBorder} style={{ borderColor: group?.colors?.[0] }} />
          </Flex>
          <Flex justify="center">
            <WppTypography type="xs-body" className={styles.groupDescription} style={{ color: group?.colors?.[2] }}>
              {group.description}
            </WppTypography>
          </Flex>
        </>
      )}
    </div>
  )
}
