import { WppAccordion, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useCallback, forwardRef, ComponentPropsWithoutRef, ReactNode, useEffect, useMemo } from 'react'

import { ColorInput } from 'components/form/formPalette/colorInput/ColorInput'
import styles from 'components/form/formPalette/FormPalette.module.scss'
import { useField } from 'hooks/form/useField'
import { TemplateColorConfig } from 'types/process/template'

export interface FormTextPaletteProps extends ComponentPropsWithoutRef<typeof WppAccordion> {
  name: string
  title?: string
  colorsConfig?: TemplateColorConfig[]
  className?: string
  disableExpanding?: boolean
  actionsSlot?: ReactNode
  useDefaultColors?: boolean
}

export const FormPalette = forwardRef<HTMLWppAccordionElement, FormTextPaletteProps>(
  ({ name, title, className, colorsConfig, disableExpanding, actionsSlot, useDefaultColors, ...props }, ref) => {
    const {
      field: { value: colors = [], onChange: setColors },
    } = useField({
      name,
    })

    const [activeColor, setActiveColor] = useState<number>()
    const defaultColors = useMemo(() => colorsConfig?.map(({ defaultValue }) => defaultValue) || [], [colorsConfig])

    const handleColorClick = useCallback(
      (index: number) => () => {
        setActiveColor(index)
      },
      [],
    )

    const updateActiveColor = useCallback(
      (color: string) => {
        const updatedColors = colors?.map((item: string, index: number) => (index === activeColor ? color : item))
        setColors(updatedColors)
      },
      [activeColor, colors, setColors],
    )

    const setThemeColors = useCallback(() => {
      const missedColorsCount = defaultColors.length - colors.length
      const missedColors = missedColorsCount > 0 ? new Array(missedColorsCount).fill('rgba(0, 0, 0, 0)') : []
      const combinedColors = [...colors, ...missedColors].slice(0, defaultColors.length)

      setColors(useDefaultColors ? defaultColors : combinedColors)
    }, [colors, defaultColors, setColors, useDefaultColors])

    useEffect(() => {
      setThemeColors()
    }, [colorsConfig]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <WppAccordion
        expanded
        withDivider={false}
        className={clsx(styles.root, className, {
          [styles.disableExpanding]: disableExpanding,
        })}
        ref={ref}
        {...props}
      >
        <WppTypography type="m-strong" slot="header">
          {title}
        </WppTypography>
        <div slot="actions">{actionsSlot}</div>

        <div className={styles.container}>
          {colors?.map((color: string, index: number) => (
            <ColorInput
              key={index}
              color={color}
              onClick={handleColorClick(index)}
              onChange={updateActiveColor}
              title={colorsConfig?.[index]?.title || ''}
            />
          ))}
        </div>
      </WppAccordion>
    )
  },
)
