import * as zod from 'zod'

export const processSchema = zod.object({
  template: zod.string().min(1),
  title: zod.string().optional().nullable(),
  description: zod.string().optional().nullable(),
  logo: zod.array(zod.any()).max(1),
  background: zod.array(zod.any()).max(1),
  positionBackground: zod.string(),
  widgetBackgroundImage: zod.array(zod.any()).max(1),
  widgetPartBackgroundImage: zod.array(zod.any()).max(1),
  colors: zod.array(zod.string()),
  button: zod.object({
    title: zod.string().optional().nullable(),
    url: zod.string().optional().nullable(),
    colors: zod.array(zod.string()).optional(),
    isVisible: zod.boolean().optional(),
  }),
  phases: zod.array(
    zod.object({
      title: zod.string().optional().nullable(),
      overlayDescriptions: zod.array(zod.object({ label: zod.string(), id: zod.number() })).optional(),
      description: zod.string().optional().nullable(),
      groupTitle: zod.string().optional().nullable(),
      image: zod.array(zod.any()).max(1),
      colors: zod.array(zod.string()).optional(),
      url: zod.string().optional().nullable(),
      innerPageEnabled: zod.boolean().optional().nullable(),
      id: zod.string().optional().nullable(),
      localId: zod.string().optional().nullable(),
    }),
  ),
  groups: zod.array(
    zod.object({
      title: zod.string().optional().nullable(),
      description: zod.string().optional().nullable(),
      colors: zod.array(zod.string()).optional(),
      phasesLocalIds: zod.array(zod.string()).optional(),
      id: zod.string().optional().nullable(),
    }),
  ),
  templateOptions: zod.object({
    flipGradient: zod.boolean().optional().nullable(),
    verticalPhaseNumbers: zod.boolean().optional().nullable(),
  }),
})
