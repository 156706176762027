import { FileItemType } from '@platform-ui-kit/components-library'
import { MayBeNull, AttachmentMetadataResponse } from '@wpp-open/core'
import * as zod from 'zod'

import { useUploadProcessFilesApi } from 'hooks/attachments/useUploadProcessFilesApi'
import { BGAlignmentOptions } from 'types/common/comon'
import { PhasePage, Templates, PhaseFormConfig, PhaseLegendVariant } from 'types/phase/phase'
import { TemplateColorConfig } from 'types/process/template'
import { isFile } from 'utils/files'

export const getTemplateColors = () => {}

export const getInitialValues = (data: MayBeNull<PhasePage>): PhaseFormConfig => {
  const getFieldValue = ({ name, size, url }: AttachmentMetadataResponse) => ({
    name,
    size,
    url: url || '',
    type: '',
  })

  return {
    template: data?.template || Templates.InnerFirst,
    background: (data?.background ? [getFieldValue(data?.background)] : []) as [FileItemType?],
    colors: data?.colors || [],
    header: {
      title: data?.header?.title || '',
      description: data?.header?.description || '',
      groupTitle: data?.header?.groupTitle || '',
      logo: (data?.header?.logo ? [getFieldValue(data?.header?.logo)] : []) as [FileItemType?],
      background: (data?.header?.background ? [getFieldValue(data?.header?.background)] : []) as [FileItemType?],
      positionBackground: data?.header?.positionBackground || BGAlignmentOptions.CenterCenter,
      colors: data?.header?.colors || [],
      isFullWidth: Boolean(data?.header?.isFullWidth),
      height: data?.header?.height || 184,
    },
    card: {
      background: (data?.card?.background ? [getFieldValue(data?.card?.background)] : []) as [FileItemType?],
      contentImage: (data?.card?.contentImage ? [getFieldValue(data?.card?.contentImage)] : []) as [FileItemType?],
      colors: data?.card?.colors || [],
      columns:
        data?.card?.columns
          .map(({ icon, ...column }) => ({
            icon: (icon ? [getFieldValue(icon)] : []) as [FileItemType?],
            ...column,
          }))
          .sort(({ order: orderA }, { order: orderB }) => (orderA && orderB ? orderA - orderB : 0)) || [],
      isEnabled: Boolean(data?.card?.isEnabled),
      isScrollable: Boolean(data?.card?.isScrollable),
    },
    legend: {
      isEnabled: Boolean(data?.legend?.isEnabled),
      icon: (data?.legend?.icon ? [getFieldValue(data?.legend?.icon)] : []) as [FileItemType?],
      colors: data?.legend?.colors || [],
      variant: data?.legend?.variant || PhaseLegendVariant.TOOLTIP,
      image: (data?.legend?.image ? [getFieldValue(data?.legend?.image)] : []) as [FileItemType?],
      title: data?.legend?.title || '',
      sections:
        data?.legend?.sections?.map(({ image, colors, ...section }) => ({
          ...section,
          colors: colors || [],
          image: (image ? [getFieldValue(image)] : []) as [FileItemType?],
        })) || [],
    },
    controls: {
      colors: data?.controls?.colors || [],
      nextUrl: data?.controls?.nextUrl,
      previousUrl: data?.controls?.previousUrl,
      button: {
        title: data?.controls?.button?.title,
        url: data?.controls?.button?.url,
        isVisible: data?.controls?.button?.isVisible,
      },
    },
  }
}

export const useMapFormValuesToPayload = () => {
  const handleUploadProcessFiles = useUploadProcessFilesApi()

  return async ({
    background,
    header: { logo: headerLogo, background: headerBackground, ...header },
    card: { background: cardBackground, contentImage: cardContent, ...card },
    legend: { image: legendTooltipImage, icon: legendTooltipIcon, ...legend },
    ...values
  }: PhaseFormConfig) => {
    const imagesMetadata = Object.fromEntries(
      await Promise.all(
        Object.entries({
          background,
          headerLogo,
          headerBackground,
          cardBackground,
          cardContent,
          legendTooltipImage,
          legendTooltipIcon,
        })
          .filter(([_, [value]]) => !value || isFile(value))
          .map(async ([key, [value]]) => [key, value ? (await handleUploadProcessFiles([value as File]))[0] : null]),
      ),
    )

    return {
      ...values,
      background: imagesMetadata.background,
      header: {
        ...header,
        logo: imagesMetadata.headerLogo,
        background: imagesMetadata.headerBackground,
      },
      card: {
        ...card,
        contentImage: imagesMetadata.cardContent,
        background: imagesMetadata.cardBackground,
        columns: await Promise.all(
          card.columns.map(async ({ icon, ...column }, index) => {
            const iconMetadata = isFile(icon?.[0]) ? (await handleUploadProcessFiles([icon?.[0]]))[0] : null

            return {
              ...column,
              ...(!icon?.[0] || isFile(icon?.[0]) ? { icon: iconMetadata } : {}),
              order: index + 1,
            }
          }),
        ),
      },
      legend: {
        ...legend,
        image: imagesMetadata.legendTooltipImage,
        icon: imagesMetadata.legendTooltipIcon,
        sections: await Promise.all(
          legend.sections.map(async ({ image, ...section }) => {
            const imageMetadata = isFile(image?.[0]) ? (await handleUploadProcessFiles([image?.[0]]))[0] : null

            return {
              ...section,
              title: section.title || '',
              colors: section.colors || [],
              ...(!image?.[0] || isFile(image?.[0]) ? { image: imageMetadata } : {}),
              ...(section.id ? { id: section.id } : {}),
            }
          }),
        ),
      },
    }
  }
}

export const getPreviewImageUrl = (value: [FileItemType?]) =>
  isFile(value?.[0]) ? URL.createObjectURL(value?.[0]) : value?.[0]?.url

export const phaseSchema = zod.object({
  template: zod.string().min(1),
  background: zod.array(zod.any()).max(1),
  colors: zod.array(zod.string()),
  header: zod.object({
    title: zod.string().optional().nullable(),
    description: zod.string().optional().nullable(),
    logo: zod.array(zod.any()).max(1),
    background: zod.array(zod.any()).max(1),
    positionBackground: zod.string(),
    colors: zod.array(zod.string()),
    isFullWidth: zod.boolean(),
    height: zod.number(),
  }),
  card: zod.object({
    background: zod.array(zod.any()).max(1),
    contentImage: zod.array(zod.any()).max(1),
    colors: zod.array(zod.string()),
    columns: zod.array(
      zod.object({
        icon: zod.array(zod.any()).max(1),
        content: zod.string().optional().nullable(),
        id: zod.string().optional().nullable(),
      }),
    ),
    isEnabled: zod.boolean(),
    isScrollable: zod.boolean(),
  }),
  legend: zod.object({
    isEnabled: zod.boolean(),
    icon: zod.array(zod.any()).max(1),
    colors: zod.array(zod.string()),
    variant: zod.nativeEnum(PhaseLegendVariant),
    image: zod.array(zod.any()).max(1),
    title: zod.string().optional().nullable(),
    sections: zod.array(
      zod.object({
        title: zod.string().optional().nullable(),
        image: zod.array(zod.any()).max(1),
        colors: zod.array(zod.string()),
        id: zod.string().optional().nullable(),
      }),
    ),
  }),
  controls: zod.object({
    previousUrl: zod.string().optional().nullable(),
    nextUrl: zod.string().optional().nullable(),
    button: zod.object({
      title: zod.string().optional().nullable(),
      url: zod.string().optional().nullable(),
      isVisible: zod.boolean().optional().nullable(),
    }),
    colors: zod.array(zod.string()),
  }),
})

const colors = {
  page: [{ title: 'Page background', defaultValue: '#fafafa' }],
  header: [
    { title: 'Header text', defaultValue: '#fff' },
    { title: 'Header background', defaultValue: '#0014CC' },
  ],
  card: [
    { title: 'Card background', defaultValue: '#fff' },
    { title: 'Column circle background', defaultValue: '#0014CC' },
    { title: 'Column text', defaultValue: '#606060' },
  ],
  legend: [
    { title: 'Legend trigger background', defaultValue: '#0014CC' },
    { title: 'Legend trigger Icon', defaultValue: '#fff' },
    { title: 'Legend title', defaultValue: '#121619' },
    { title: 'Legend background', defaultValue: '#fff' },
    { title: 'Legend close icon button', defaultValue: '#121619' },
    { title: 'Legend close button', defaultValue: '#0014CC;' },
    { title: 'Legend close button hover', defaultValue: '#fff' },
  ],
  legendSection: [
    { title: 'Section title', defaultValue: '#4D5358' },
    { title: 'Section separator', defaultValue: '#4D5358' },
  ],
  controls: [
    { title: 'Navigation text', defaultValue: '#0014CC' },
    { title: 'Button background', defaultValue: '#0014CC' },
    { title: 'Button text', defaultValue: '#fff' },
  ],
}

export const colorsConfig: Record<
  Templates,
  {
    page: TemplateColorConfig[]
    header: TemplateColorConfig[]
    card: TemplateColorConfig[]
    legend: TemplateColorConfig[]
    legendSection: TemplateColorConfig[]
    controls: TemplateColorConfig[]
  }
> = {
  [Templates.InnerFirst]: colors,
  [Templates.InnerSecond]: colors,
}
