import { WppButton, WppIconGear, WppSpinner } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { usePhaseApi } from 'api/phase/queries/useFetchPhaseApi'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermissions'
import { showEditPhaseModal, useEditPhaseModal } from 'pages/phaseBuilder/editPhaseModal/EditPhaseModal'
import { EmptyState } from 'pages/phaseBuilder/emptyState/EmptyState'
import { useLegendSideModal } from 'pages/phaseBuilder/legendSideModal/LegendSideModal'
import styles from 'pages/phaseBuilder/PhaseBuilder.module.scss'
import { SimpleCard } from 'pages/phaseBuilder/templates/simpleCard/SimpleCard'
import { getPreviewImageUrl, getInitialValues } from 'pages/phaseBuilder/utils'
import { Templates } from 'types/phase/phase'

export const PhaseBuilder = () => {
  const { phaseId, processId } = useParams()
  const { t } = useTranslation()
  const { hasPermission } = useHasPermission()
  const isAdmin = hasPermission(Permission.OS_PROCESS_PAGE_ENTITIES_MANAGE)
  const { visible: isEditMode } = useEditPhaseModal()
  const { visible: isLegendSideModalVisible } = useLegendSideModal()

  const { data, refetch, isLoading } = usePhaseApi({
    params: {
      phaseId: phaseId || '',
    },
  })
  const [config, setConfig] = useState(getInitialValues(data))
  useEffect(() => {
    setConfig(getInitialValues(data))
  }, [data])

  const resetEditState = useCallback(async () => {
    const { data: updatedData } = await refetch()
    updatedData?.data && setConfig(getInitialValues(updatedData.data) as any)
  }, [refetch])

  const handleOpenEdit = useCallback(() => {
    showEditPhaseModal({ onChange: setConfig, resetEditState: resetEditState, phaseId, processId })
  }, [resetEditState, phaseId, processId])

  const Template = useMemo(() => {
    switch (config.template) {
      case Templates.InnerFirst:
      case Templates.InnerSecond:
      default:
        return SimpleCard
    }
  }, [config.template])

  if (isLoading) return <WppSpinner className={styles.loader} size="l" />
  if (!data && !isEditMode) return <EmptyState isAdmin={isAdmin} onConfigureClick={handleOpenEdit} />

  return (
    <div
      className={clsx(styles.root, { [styles.editMode]: isEditMode || isLegendSideModalVisible })}
      style={{ backgroundImage: `url(${getPreviewImageUrl(config?.background)})` }}
    >
      <div className={styles.bgColor} style={{ background: config?.colors?.[0] }} />

      <Template config={config} />

      {isAdmin && !isEditMode && (
        <WppButton onClick={handleOpenEdit} className={styles.editBtn} variant="primary">
          <WppIconGear slot="icon-start" />
          {t('phase.page.edit')}
        </WppButton>
      )}
    </div>
  )
}
