import { WppButton, WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useCallback, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import breaks from 'remark-breaks'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular3/Circular3Process.module.scss'
import { Circular3Svg } from 'pages/processBuilder/templates/circular3/Circular3Svg'
import { ProcessFormConfig } from 'types/process/process'

export const Circular3Process = ({ config }: { config: ProcessFormConfig }) => {
  const [activePhase, setActivePhase] = useState<MayBeNull<number>>(null)

  const handlePhaseEnter = useCallback(
    (phase: number) => () => {
      setActivePhase(phase)
    },
    [],
  )

  const handlePhaseLeave = useCallback(() => {
    setActivePhase(null)
  }, [])

  return (
    <Flex align="center" justify="center" direction="column" className={styles.root}>
      <WppGrid container>
        <Link
          to={config?.button?.url || ''}
          className={styles.projectLink}
          style={{ visibility: config?.button?.isVisible ? 'visible' : 'hidden' }}
        >
          <WppButton
            className={styles.projectButton}
            style={{ background: config?.button?.colors?.[0], color: config?.button?.colors?.[1] }}
          >
            {config?.button?.title}
          </WppButton>
        </Link>
      </WppGrid>

      <Circular3Svg
        onPhaseEnter={handlePhaseEnter}
        onPhaseLeave={handlePhaseLeave}
        activePhase={activePhase}
        config={config}
      />

      {typeof activePhase === 'number' && (
        <Flex className={styles.phaseInfo} direction="column" gap={5}>
          <WppTypography
            type="2xl-heading"
            className={styles.descriptionTitle}
            style={{
              color: config.phases?.[activePhase]?.colors?.[4] || '#001023',
              borderColor: config.phases?.[activePhase]?.colors?.[4] || '#001023',
            }}
          >
            {config.phases?.[activePhase]?.title}
          </WppTypography>

          <WppTypography
            type="l-body"
            className={styles.description}
            style={{ color: config.phases?.[activePhase]?.colors?.[4] || '#001023' }}
          >
            <ReactMarkdown
              remarkPlugins={[breaks]}
              children={config.phases?.[activePhase]?.description?.replace(/\\n/g, '\n')}
            />
          </WppTypography>
        </Flex>
      )}
    </Flex>
  )
}

export default Circular3Process
